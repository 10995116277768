.viewMainDiv {
  padding-bottom: 25px;
}

.searchBar {
  background-color: #FBFBFB;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  border-radius: 10px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

  &:active, &:focus { 
    border: 1px solid white;
    outline: none; 
  }
}

.topbar {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0px;
  width: 96%;
  z-index: 999;
}

.toprow {
  backdrop-filter: blur(7.4px);
  -webkit-backdrop-filter: blur(7.4px);
  border-radius: 12px;
}

.switch {
  position: relative;
  width: 50%;
  text-align: center;
  padding-top: 25px;
}

.card {
  background-color: transparent;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  margin: 6px;
}

.searchBar::placeholder {
  color: rgb(161, 161, 161);
}

.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.17);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.4px);
    -webkit-backdrop-filter: blur(7.4px);
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .popup-content {
    background: transparent;
    padding: 20px;
    border-radius: 10px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
  }
  
  .popup-content pre {
    margin: 0;
  }

  .codeSyntax {
    background: transparent !important; 
    height: fit-content;
    max-height: 260px;
    font-size: 12px;
    scrollbar-width: thin;
  }
  
  .head-of-card {
    text-align: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 20vh;
    max-height: 20vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    font-size: 16px;
  }

  .head-of-card h1 {
    padding-top: 20px;
    text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
    color: white;
  }

  .createDate {
    font-size: 14px;;
  }

  .copyied-alert {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    padding: 20px;
    border-radius: 4px;
  }
  
  .html {
    border: 5px solid #574964;
  }

  .javascript {
    border: 5px solid #E5989B;
  }
  
  .python {
    border: 5px solid #FFE6A9;
  }

  .vba {
    border: 5px solid #A5B68D;
  }

  .other {
    border: 5px solid white;
  }