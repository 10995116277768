textarea, input, select {
    background-color: #FBFBFB;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;

    &:active, &:focus { 
        border: 1px solid white;
        outline: none;  
      }
}

.addTextarea {
  height: 271px;
}